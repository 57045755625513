<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      class="
        dark-light-icon
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-none d-lg-flex
      "
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link :to="{ name: 'login' }" class="brand-logo">
          <h2 class="brand-text text-primary ml-1">FiqOn</h2>
          <b-img
            fluid
            class="fiqon-logo"
            :class="logoUrl ? '' : 'light-logo'"
            :src="logoImg"
            alt="IP Validation"
          />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('access_point_validation.validate_message') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('access_point_validation.sent_to_email') }}
          <br />
        </b-card-text>

        <!-- form -->
        <validation-observer ref="codeValidation">
          <b-form
            class="new-access-point-validation mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Confirmation Code -->
            <b-form-group
              :label="$t('access_point_validation.code_label')"
              label-for="IP-confirmation-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmation Code"
                rules="required"
              >
                <b-form-input
                  id="IP-confirmation-code"
                  v-model="userConfirmationCode"
                  :state="errors.length > 0 ? false : null"
                  name="IP-confirmation-code"
                  placeholder="- - - - - - "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              {{ $t('access_point_validation.validate_ip') }}
            </b-button>
            <b-button
              variant="secondary"
              block
              id="resend-code"
            >
              {{ $t('access_point_validation.resend_confirmation') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> 
            {{ $t('access_point_validation.back_to_login')}}
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required, email } from "@validations";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store/index";
import Animation from "/src/layouts/components/Animation.vue";
import custom from "@/custom";
import { makeToast } from '@/layouts/components/Popups'


export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    DarkToggler,
    Animation,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userConfirmationCode: "",
      // validation
      required,
      email,
      logoImg: require("@/assets/images/logo/logo.svg"),
    };
  },
  computed: {
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return false;
      }
      return true;
    },
  },
  methods: {
    validationForm() {
      this.$refs.codeValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('sendAccessPointValidation', this.userConfirmationCode )
            .then(response => {
              if(response){                
                this.$router.replace( { name: 'login' } )
                  .then(() => {
                    makeToast(custom.successMessages.ip_verified)            
                  })
              }else{
                alert('Oops, there was an error in vuex')
              }
          }).catch(error => {
            console.log('opa', error.message)
          })
        }
      })
    },
    async preventButtonSpam(){
      setTimeout(async () => await this.toogleButton(true), 3000)
    },
    toogleButton(state){
      return this.disabledTemp = state
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.fiqon-logo-welcome {
  height: 17px;
}
.brand-name {
  color: $primary;
}
</style>